<template>
	<div class="picture-editing">
		<div class="wrapper">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>图片编辑</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="editing-con">
				<div class="title">
					<h1>备品名称：左前立柱下铰链护板</h1>
					<h4>备品号：LI2035103370-DP830</h4>
				</div>
				<div v-for="(item,index) in pictureList" :key="index" class="picture-item">
					<el-upload class="picture-uploader" name="img" :show-file-list="false"
						action="https://jsonplaceholder.typicode.com/posts/"
						:on-success="handleUploadSuccess.bind(null,{'index':index,'data':item})"
						accept="image/jpeg,image/gif,image/png">
						<el-image fit="contain" :src="item.imgUrl" class="img" @click.stop="previewImg(item)">
						</el-image>
						<div class="btn">
							<el-button size="small" type="primary">替换图片</el-button>
							<el-button size="small" type="primary" @click.stop="download(item)">图片下载</el-button>
						</div>
						<div @click.stop="mainPicture" class="main-picture">
							<el-checkbox v-model="item.mainPicture">设为主图</el-checkbox>
						</div>
					</el-upload>
				</div>
			</div>
			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
			<el-button class="main-btn submit-btn" type="primary" @click="submitClick">立即提交</el-button>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pictureRadio:'',
				dialogImageUrl: '',
				dialogVisible: false,
				pictureList: [{
					imgUrl: 'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
				}, {
					imgUrl: 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
				},{
					imgUrl: 'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
				}, {
					imgUrl: 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
				},{
					imgUrl: 'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
				}, {
					imgUrl: 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
				},{
					imgUrl: 'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
				}, {
					imgUrl: 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
				},{
					imgUrl: 'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
				}, {
					imgUrl: 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
				},{
					imgUrl: 'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
				}, {
					imgUrl: 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
				}]
			}
		},
		methods: {
			mainPicture(){
				
			},
			submitClick(){
				
			},
			previewImg(item) {
				this.dialogImageUrl = item.imgUrl;
				this.dialogVisible = true;
			},
			download(item) {
				console.log(item)
			},
			handleUploadSuccess(obj, res, file) {
				console.log(obj, res, file);
			},
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/picture-editing.scss";
</style>
